<script lang="ts" setup>
import { computed, useRouteBaseName, useHead, useRoute } from '#imports';

useHead({ bodyAttrs: { class: 'airpaz-desktop' } });

const getRouteBaseName = useRouteBaseName();

const showNavbar = computed(
  () =>
    ![
      'index',
      'airlines',
      'airlines-slug',
      'flight',
      'flight-book-bookId',
      'flight-search',
      'flight-confirm',
      'pay-trxId-choose',
      'pay-trxId-confirmation',
      'login',
      'signup',
      'signup-verify',
      'signup-setup-account',
      'reset',
      'reset-pass-id',
      'hotel',
      'hotel-search',
      'choose-country',
      'newsletter-confirm',
      'newsletter-unsubscribe',
      'newsletter-choose-language',
      'newsletter-change-language',
      'flight-book-bookId-addons',
    ].includes(getRouteBaseName(useRoute()) ?? ''),
);
const showFooter = computed(
  () =>
    ![
      'flight-search',
      'login',
      'reset',
      'reset-pass-id',
      'callback',
      'signup',
      'signup-verify',
      'signup-setup-account',
      'reset',
      'reset-pass-id',
      'hotel-search',
      'choose-country',
    ].includes(getRouteBaseName(useRoute()) ?? ''),
);
</script>

<template>
  <div>
    <AppNavbar
      v-if="showNavbar"
      alternate
    />
    <slot />
    <AppFooter v-if="showFooter" />
  </div>
</template>
